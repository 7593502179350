<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback />  {{ $t('Customer Summary') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            dense
            :label="`${$t('search')} (${$t('pressEnterForSearch')})`"
            outlined
            @keypress.enter="addPayload"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-select
            v-model="typeSelected"
            :items="typeList"
            dense
            outlined
            :label="$t('type')"
            item-text="name"
            item-value="id"
            @change="addPayload()"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model="monthSelect"
            dense
            outlined
            :items="dataMonthList"
            :label="$t('choose_birth_month')"
            :item-text="$i18n.locale"
            item-value="value"
            @change="addPayload()"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-dialog
            ref="datePickerStart"
            v-model.trim="isShowDate1"
            :return-value.sync="dateStart"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateStart"
                :label="$t('add_on')"
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="dateStart"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.datePickerStart.save(dateStart);addPayload()"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate1 = false"
              >
                {{ $t("cancel") }}
              </v-btn>

              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-dialog
            ref="datePickerEnd"
            v-model.trim="isShowDate2"
            :return-value.sync="dateEnd"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateEnd"
                :label="$t('to')"
                readonly
                outlined
                dense
                v-bind="attrs"

                v-on="on"
              >
                <template v-slot:append>
                  <v-tooltip
                    color="#212121"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="dateStart = '';dateEnd=''"
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t("all_dates") }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="dateEnd"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>

              <v-btn
                text
                color="primary"
                @click="$refs.datePickerEnd.save(dateEnd);addPayload()"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate2 = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.number`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.customer_status_id`]="{ item }">
          <StatusBlock :status="String(item.customer_status_id)" />
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; addPayload()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; addPayload()}"
      />
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCalendarBlank, mdiFileExcelOutline } from '@mdi/js'
import monthList from '@/@fake-db/data/monthList.json'
import Comeback from '../Comeback.vue'
import { i18n } from '@/plugins/i18n'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { reportCustomer, removeComma } from '../useExcel'
import { sumdate } from '@/plugins/filters'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    Comeback,
    Pagination,
    StatusBlock,
  },

  setup() {
    const XLSX = require('xlsx')
    const typeList = ref([
      { id: 1, name: 'เพิ่มลูกค้าเมื่อ' },
      { id: 2, name: 'ชำระเงินเมื่อ' }])
    const typeSelected = ref(1)
    const monthSelect = ref('')
    const dataMonthList = ref(monthList.data)
    const searchtext = ref('')
    const isShowDate1 = ref(false)
    const isShowDate2 = ref(false)
    const dateStart = ref('')
    const dateEnd = ref('')
    const payload = ref({})
    const dataTableList = ref([])
    const totalDataTableList = ref(0)
    const exportLoading = ref(false)
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('customer_code'), value: 'customer_id', width: 110 },
      { text: i18n.t('firstname_lastname'), value: 'customer_fullname', width: 220 },
      { text: i18n.t('tel'), value: 'customer_tel', width: 110 },
      { text: i18n.t('group_customers'), value: 'customer_group_name', width: 180 },
      {
        text: i18n.t('reward_points'), value: 'customer_point', width: 110, align: 'end',
      },
      {
        text: i18n.t('status'), value: 'customer_status_id', width: 110, align: 'center',
      },
      { text: i18n.t('add_on'), value: 'customer_create', width: 150 },
      {
        text: i18n.t('total_purchase'), value: 'order_totalpay', width: 150, align: 'end',
      },
      { text: i18n.t('id_card'), value: 'customer_idcard', width: 150 },
      { text: i18n.t('gender'), value: 'customer_gender', width: 100 },
      { text: i18n.t('email'), value: 'customer_email', width: 150 },
      { text: i18n.t('blood_type'), value: 'customer_blood', width: 150 },
      { text: i18n.t('birthday'), value: 'customer_birthdate', width: 150 },
      { text: i18n.t('address'), value: 'customer_address', width: 300 },
      { text: i18n.t('be_allergic'), value: 'customer_allergic', width: 150 },
      { text: i18n.t('congenital_disease'), value: 'customer_disease', width: 150 },
      { text: i18n.t('note'), value: 'customer_comment', width: 150 },
      { text: i18n.t('tag'), value: 'customer_tags', width: 150 },
    ])
    const options = ref({})
    const loading = ref(false)
    const segmentId = ref(0)
    const totalPage = ref(0)
    const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)

    const addPayload = () => {
      payload.value = {
        searchtext: searchtext.value,
        birth_month: monthSelect.value,
        start: dateStart,
        end: dateEnd,
        lang: i18n.locale,
        type: typeSelected,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      const { data, pageData } = await reportCustomer(payload)
      data.map((item => {
        item.customer_create = sumdate(item.customer_create, i18n.locale)

        return item
      }))
      dataTableList.value = data
      totalDataTableList.value = pageData.count
      segmentId.value = pageData.segment
      totalPage.value = pageData.count_of_page
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end, search } = payload.value
      const fileName = `${i18n.t('CustomerSummary')}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const { data: dataTmp } = await reportCustomer({
        start,
        end,
        search,
      })
      dataTmp.map(item => {
        item.customer_status_id = item.customer_status_id == 1
          ? i18n.t('normal')
          : i18n.t('suspend')

        return item
      })
      const dataExport = await removeComma(
        JSON.parse(JSON.stringify(dataTmp)),
      )

      const Heading = [
        [`${i18n.t('CustomerSummary')} `],
        [
          '#',
          `${i18n.t('customer_code')}`,
          `${i18n.t('prefix')}`,
          `${i18n.t('firstname')}`,
          `${i18n.t('lastname')}`,
          `${i18n.t('tel')}`,
          `${i18n.t('group_customers')}`,
          `${i18n.t('reward_points')}`,
          `${i18n.t('status')}`,
          `${i18n.t('add_on')}`,
          `${i18n.t('total_purchase')}`,
          `${i18n.t('id_card')}`,
          `${i18n.t('gender')}`,
          `${i18n.t('email')}`,
          `${i18n.t('blood_type')}`,
          `${i18n.t('birthday')}`,
          `${i18n.t('address')}`,
          `${i18n.t('be_allergic')}`,
          `${i18n.t('congenital_disease')}`,
          `${i18n.t('note')}`,
          `${i18n.t('tag')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'customer_id',
          'customer_prefix',
          'customer_fname',
          'customer_lname',
          'customer_tel',
          'customer_group_name',
          'customer_point',
          'customer_status_id',
          'customer_create',
          'order_totalpay',
          'customer_idcard',
          'customer_gender',
          'customer_email',
          'customer_blood',
          'customer_birthdate',
          'customer_address',
          'customer_allergic',
          'customer_disease',
          'customer_comment',
          'customer_tags',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 12 } }]
      const wscols = [
        { wch: 8 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 18 },
        { wch: 10 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 40 },
        { wch: 12 },
        { wch: 12 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${i18n.t('CustomerSummary')}`,
      )

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    watch([options], () => {
      addPayload()
    })

    return {
      typeList,
      typeSelected,
      searchtext,
      monthSelect,
      dataMonthList,
      serachReport,
      addPayload,
      exportExcel,
      isShowDate1,
      isShowDate2,
      exportLoading,
      dateStart,
      dateEnd,
      payload,
      dataTableList,
      totalDataTableList,
      columns,
      segmentId,
      options,
      loading,
      totalPage,
      footer,
      mdiFileExcelOutline,
      icons: {
        mdiCalendarBlank,
      },
    }
  },
}
</script>
